<template>
  <div class="container" v-if="Object.keys(this.checkout).length">
    <div class="cart-title">
      <div class="title">Корзина</div>
    </div>

    <div v-show="showGift" class="cart-items">
      <div class="cart-items__title title">Выберите подарок:</div>
      <CartItem @removeEl="removeEl" v-for="item in gift" :key="parseInt(item.ID)" :item="item"/>
    </div>

    <div class="cart-items">
      <div class="cart-items__title title">{{ quantityProduct() }}</div>

      <CartItem v-for="item in checkout.items" :key="item.id" :item="item" :unavailable="checkout.basketHasNotAvailableItems ? checkout.notAvailableItems : []"/>
    </div>
    <div class="cart-info">
      <div class="cart-info__element">
        <div class="cart-info__element-name">Сумма без скидки</div>
        <div class="cart-info__element-value">{{ checkout.amount }} ₽</div>
      </div>
      <div class="cart-info__element cart-info__element--stock">
        <div class="cart-info__element-name">Скидка</div>
        <div class="cart-info__element-value">
          {{ checkout.discountBasket }} ₽
        </div>
      </div>
      <div class="cart-info__element cart-info__element--sum">
        <div class="cart-info__element-name">Итого:</div>
        <div class="cart-info__element-value">{{ checkout.amountOrder }} ₽</div>
      </div>
    </div>
    <div class="cart-buy">
      <div class="container">
        <div class="cart-buy__wrapper">
          <div class="cart-buy__sum">
            <div class="cart-buy__sum-span">Итого:</div>
            <div class="cart-buy__sum-price">{{ checkout.amountOrder }} ₽</div>
          </div>
          <div class="cart-buy__btn">
            <button class="btn" @click="$router.push('/checkout/ordering')">
              Оформить заказ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div class="free-content">
      <div class="free-content__icon">
        <img src="/img/icons/basket.svg" />
      </div>
      <div class="free-content__title title">Корзина ждет товаров</div>
      <div class="free-content__btn">
        <router-link class="btn" to="/catalog/menu">
          Перейти в каталог
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/cards/cart-item";
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/checkout-guest");
    }
  },

  components: {
    CartItem,
  },

  data() {
    return {
      showGift: true,
    };
  },

  computed: {
    checkout() {
      if (Object.keys(this.$store.state.basket).length > 0) {
        return this.$store.state.basket;
      } else {
        return [];
      }
    },
    gift() {
      if (Object.keys(this.$store.state.gift).length > 0) {
        return Object.values(this.$store.state.gift);
      } else {
        this.removeEl()
        return [];
      }
    },
  },

  methods: {
    quantityProduct() {
      if (Object.keys(this.checkout).length) {
        const cases = [2, 0, 1, 1, 1, 2];
        const words = ["товар", "товара", "товаров"];
        const number = this.checkout.items.length;

        return (
          number +
          " " +
          words[
            number % 100 > 4 && number % 100 < 20
              ? 2
              : cases[number % 10 < 5 ? number % 10 : 5]
            ]
        );
      }
    },
    removeEl() {
      this.showGift = false
    }
  },
};
</script>
